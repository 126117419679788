// Banner Styles
.banner {
	padding: 100px 0;

	.block {
		h1 {
			margin-bottom: 13px;
		}

		p {
			font-size: 20px;
			margin-bottom: 30px;
		}

		.video {
			width: 80%;
			margin: 0 auto;

			@include tablet {
				width: 100%;
			}
		}

		ul.clients-logo {
			margin-top: 30px;

			li {
				margin-left: 30px;

				@include mobile {
					margin-bottom: 20px;
				}

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.slider {
	padding: 180px 0 300px;
	text-align: center;
	position: relative;
	overflow: hidden;

	.block {
		position: relative;

		h1,
		h3 {
			color: $light;
		}

		.download {
			margin-top: 20px;
		}

		.image-content {
			text-align: center;

			img {
				margin-top: 100px;
				margin-bottom: -200px;
			}
		}
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		border-bottom: 290px solid #fff;
		border-left: 2000px solid transparent;
		width: 0;
	}
}

.services {
	.service-block {
		background: $light;
		padding: 30px 40px;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;

			@include mobile {
				margin-bottom: 30px;
			}
		}

		border-radius: 5px;

		h3 {
			line-height: 30px;
			text-transform: capitalize;
			font-size: 16px;
			font-weight: 500;
		}

		i {
			font-size: 30px;
			color: $primary-color;
			margin-bottom: 15px;
			display: inline-block;

		}

		p {
			margin-bottom: 0;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.app-preview {
		display: flex;
		justify-content: center !important;

		img {
			height: 500px;
			width: auto;
		}
	}

	@include desktop {
		.col-lg-4.m-auto {
			display: none;
		}
	}
}

.service {
	@extend .hide-overflow;

	.service-thumb {
		@include tablet {
			width: 80%;
			margin: 0 auto;
		}
	}

	.service-box {
		padding: 20px;
		background: $light;
		border-radius: 4px;
		@extend .shadow;

		@include tablet {
			width: 80%;
			margin: 0 auto;
		}

		.service-item {
			text-align: center;
			padding: 10px;
			margin: 20px 0;

			i {
				font-size: 20px;
				color: $primary-color;
				display: inline-block;
				margin-bottom: 10px;
			}

			p {
				font-size: 14px;
			}
		}
	}
}

.feature {
	.feature-content {

		h2,
		p {
			margin-bottom: 25px;

			@include tablet {
				text-align: center;
			}
		}
	}

	.testimonial {
		@include tablet {
			text-align: center;
		}

		p {
			font-family: $secondary-font;
			margin-bottom: 10px;
			font-style: italic;
			color: #242424;
		}

		ul.meta {
			li {
				font-size: 12px;
				margin-right: 10px;

				img {
					height: 40px;
					width: 40px;
					border-radius: 100%;
				}
			}
		}
	}
}

// App Features
.app-features {
	.app-feature {
		@include mobile {
			margin-bottom: 30px;
		}
	}

	.app-explore {
		display: flex;
		justify-content: center !important;
		margin-bottom: 40px;
	}
}

.banner-full {
	.image {
		display: flex;
		justify-content: center;

		img {
			height: 625px;
		}

		@include tablet {
			margin-bottom: 30px;
		}
	}

	.block {
		@include tablet {
			text-align: center;
		}

		.logo {
			margin-bottom: 40px;
		}

		h1 {
			margin-bottom: 40px;
		}

		p {
			font-size: 20px;
			margin-bottom: 50px;
		}

		.app {
			margin-bottom: 20px;
		}
	}
}

.video-promo {
	padding: 150px 0;

	.content-block {
		width: 60%;
		margin: 0 auto;
		text-align: center;

		h2 {
			font-size: 30px;
			color: $light;
		}

		p {
			margin-bottom: 30px;
		}

		a {
			i.video {
				height: 125px;
				width: 125px;
				background: $primary-color;
				display: inline-block;
				font-size: 40px;
				color: $light;
				text-align: center;
				line-height: 125px;
				border-radius: 100%;
			}

			&:focus {
				outline: 0;
			}
		}
	}
}

.testimonial {
	.testimonial-slider {
		.item {
			padding-bottom: 10px;

			.block {
				padding: 40px;
				text-align: center;
				margin: 10px;
				border-radius: 5px;

				.image {
					margin-top: 30px;
					margin-bottom: 5px;
					width: 100%;
					display: flex;
					justify-content: center;

					@include tablet {
						flex-grow: 1;
					}

					img {
						height: 40px;
						width: 40px;
						border-radius: 100%;
					}
				}

				p {
					font-family: $secondary-font;
					font-style: italic;
					color: #888888;
				}

				cite {
					font-style: normal;
					font-size: 14px;
					color: #161616;
				}
			}
		}

		// Owl dot Active color overide
		.owl-dots {
			.owl-dot {
				&:hover {
					span {
						background: $primary-color;
					}
				}

				&.active {
					span {
						background: $primary-color;
					}
				}
			}
		}
	}
}

/* homepage 3 */
.gradient-banner{
	padding: 100px 0 170px;
	position: relative;
	overflow: hidden;
	&::before{
    position: absolute;
		content: '';
    bottom: 0;
		left: 50%;
		transform: translateX(-50%);
    width: 200%;
    height: 200%;
    border-radius: 50%;
    background-image: linear-gradient(45deg,#009EC5 0%, $primary-color 20%,#02225B 50%);
	}
}

.pull-top{
	margin-top: -100px;
}

.shapes-container {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.shape {
		position: absolute;

		&::before {
			content: '';
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(255, 255, 255, .1);
			transform: rotate(-35deg);
			position: absolute;
			border-radius: 50px;
		}
	}

	.shape:nth-child(1) {
		top: 2%;
		left: 11%;
		width: 400px;
		height: 70px;
	}

	.shape:nth-child(2) {
		top: 14%;
		left: 18%;
		width: 200px;
		height: 15px;
	}

	.shape:nth-child(3) {
		top: 80%;
		left: 4%;
		width: 300px;
		height: 60px;
	}

	.shape:nth-child(4) {
		top: 85%;
		left: 15%;
		width: 100px;
		height: 10px;
	}

	.shape:nth-child(5) {
		top: 5%;
		left: 50%;
		width: 300px;
		height: 25px;
	}

	.shape:nth-child(6) {
		top: 4%;
		left: 52%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(7) {
		top: 80%;
		left: 70%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(8) {
		top: 55%;
		left: 95%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(9) {
		top: 50%;
		left: 90%;
		width: 300px;
		height: 50px;
	}

	.shape:nth-child(10) {
		top: 30%;
		left: 60%;
		width: 500px;
		height: 55px;
	}

	.shape:nth-child(11) {
		top: 60%;
		left: 60%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(12) {
		top: 35%;
		left: 75%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(13) {
		top: 90%;
		left: 40%;
		width: 300px;
		height: 45px;
	}

	.shape:nth-child(14) {
		top: 54%;
		left: 75%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(15) {
		top: 50%;
		left: 90%;
		width: 200px;
		height: 5px;
	}

	.shape:nth-child(16) {
		top: 50%;
		left: 81%;
		width: 100px;
		height: 5px;
	}
}

.zindex-1{
	z-index: 1;
}

hr {
	display: block;
	height: 2px;
	border: 0;
	border-top: 2px solid $primary-color;
	margin: 1em 0;
	padding: 0;
}

.icon-box{
	height: 80px;
	width: 80px;
	text-align: center;
	background: $primary-color;
	i{
		line-height: 80px;
		font-size: 30px;
	}
}